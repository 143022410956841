import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
export default function AppAction(props) {
    const app = props.app;
    if (app.status === "Beta") {
        return (
            <ButtonGroup>
            <Button className="btn btn-primary ml-auto" onClick={() => window.open(app.url, '_blank')}>Open</Button>
            </ButtonGroup>
        );
    } else {
        return null;
    }
}