import React from 'react';
import Home from './components/Home';
import Apps from './components/Apps';
import InviteFriends from './components/InviteFriends';

const routes = {
    "/": () => (isAuthenticated) => isAuthenticated ? <Apps appsOption="All"/> : <Home />,
	"/apps": () => (isAuthenticated) => <Apps appsOption="All"/>,
	"/apps-coming-soon": () => (isAuthenticated) => <Apps appsOption="coming-soon"/>,
	"/invite-friends": () => (isAuthenticated) => isAuthenticated ? <InviteFriends isAuthenticated={isAuthenticated} /> : null,
}

export default routes;
