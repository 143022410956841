import React, { useState, useEffect } from 'react';
import {usePath, useRoutes } from 'hookrouter';
import routes from './router';
import NotFound from './components/NotFound';
import { useAuth, MySpinner } from 'appshome-react';
import Header from './components/Header';
import Sidebar from './components/Sidebar'
import config from './config';
import Home from './components/Home';

function App() {
	const mediaQuery = "(min-width: 992px)";
	const params = {ssoScriptUrl: config.SESSION_JS_URL, clientId: config.appshome.CLIENT_ID, authCallback: processAuthCode}
	const {isAuthenticating, user} = useAuth(params);
	const routeResult = useRoutes(routes);
	const path = usePath();
	console.log("Current path: " + path);
	const [showSidebar, toggleSidebar] = useState(false);
	const media = window.matchMedia(mediaQuery);
	const [isLGBreakPoint, setIsLGBreakPoint] = useState(media.matches);
	console.log("Initial value of isLGBreakPoint: " + isLGBreakPoint);
	const handleMediaChange = () => {
		setIsLGBreakPoint(media.matches);
		console.log("Value of isLGBreakPoint: " + isLGBreakPoint);
	};
	media.addListener(handleMediaChange);
	const isAuthenticated = user ? true : false;
	if (isAuthenticating) {
		return (<MySpinner/>);
	} else {
		if(!user && path ==="/") {
			return(<Home />);
		}
		return (
			<div className="h-100 flex">
				<Header isAuthenticated={isAuthenticated} toggleSidebar={toggleSidebar} showSidebar={showSidebar} user="Gopal" />
				<div className="row h-100 flex flex-row">
					{(isLGBreakPoint || showSidebar) && <div className="col-xs-12 col-lg-2"><Sidebar  toggleSidebar={toggleSidebar} showSidebar={showSidebar} isLGBreakPoint={isLGBreakPoint} isAuthenticated={isAuthenticated} /></div>}
					{(isLGBreakPoint || (!isLGBreakPoint && !showSidebar)) && <div className="col-lg-10 col-xs-12">{routeResult(isAuthenticated) || <NotFound />}</div>}
				</div>
			</div>
		);
	}
}
async function processAuthCode(authCode) {
	const res = await fetch(config.appshome.API_HOST + "/process_auth_code/" + authCode, { mode: 'cors' })
	return res.json();
}
export default App;