import React from 'react';
import { Container } from 'react-bootstrap';
import {navigate} from 'hookrouter';
import config from '../config';

export default function Home() {
    const host = window.location.protocol + "//" + window.location.host;
    return (
        <Container className="vh-100">
            <div className="jumbotron  d-flex align-items-center justify-content-center h-100 p-3">
                <div className="justify-content-center align-items-center">
                    <div className="my-5">
                        <h1  className="h1 display-4 strong" >Apps Home</h1>
                    </div>
                    <h2 className="h5">Home for web based applications.</h2>
                    <div>
                        <p className="lead">Works on all the moddern browsers</p>
                        <p className="lead">No installation needed</p>
                        <p className="lead">Doesn't requires any storage.</p>
                        <p className="lead">Enjoy your apps across various devices</p>
                        <button className="btn-primary btn-lg btn-block" onClick={()=> navigate("/apps")}>Checkout Apps</button>
                        <p className="lead">Already an user?</p>
                        <button className="btn-success btn-lg btn-block" onClick={()=>window.location.href=config.AUTH_LOGIN_URL + "?redirect_url=" + host + "/apps"}>Sign in</button>
                        <p className="lead">Don't have an account?</p>
                        <button className="btn-secondary btn-lg btn-block" onClick={()=>window.location.href=config.AUTH_REGISTER_URL}>Sign up</button>
                    </div>
                </div>
            </div>
        </Container>
    );
}