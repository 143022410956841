import React from 'react';
import {CardColumns, Container, Row } from 'react-bootstrap';
import AppItem from './AppItem';
import { MySpinner } from 'appshome-react';
import useApps from '../hooks/apps-hooks';

export default function Apps(props) {
    const [apps, isLoading] = useApps(props.appsOption);
    if (isLoading) {
        return (
            <MySpinner />
        );
    } else {
        return (
                <Row>
                <CardColumns className="mt-5 ml-3 mr-3">
                    {apps.map((app, i) => <AppItem appItem={app} key={i} />)}
                </CardColumns>
                </Row>
        )
    }
}
