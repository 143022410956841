import { useState, useEffect } from 'react';
import config from '../config';

export default function useApps(appsOption) {
    console.log("Inside useApps hooks");
    const [apps, setApps] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let appsPath = "/apps";
    if (appsOption === "coming-soon") {
        appsPath = "/apps/coming-soon";
    }
    useEffect(() => {
        setIsLoading(true);
        fetch(config.appshome.API_HOST + appsPath, { mode: 'cors' })
            .then(res => res.json())
            .then(res => {
                console.log("Fetched apps: " + JSON.stringify(res));
                setIsLoading(false);
                setApps(res);
            })
            .catch(err => {
                console.log("Error while fetching apps: " + err)
                setIsLoading(false);
                setApps([]);
            });
    }, [appsPath]);
    return [apps, isLoading];
}