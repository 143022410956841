import React from 'react';
import { Card } from 'react-bootstrap';
import AppAction from './AppAction';

function AppItem(props) {
    const app = props.appItem;
    return (
        <Card style={{ width: '400px', height:'300px',backgroundColor:'#F1F1F1', borderRadius:'20px' }}>
            <Card.Header variant="dark"><h2>{app.app_name}</h2></Card.Header>
            <Card.Body>
                <Card.Title>{app.description}</Card.Title>
                <Card.Text>Created by: {app.creator}.</Card.Text>
                <Card.Text>Status: {app.status}.</Card.Text>

            </Card.Body>
            <Card.Footer>
                <AppAction app={app} />
            </Card.Footer>
        </Card>
    );
}
export default AppItem;