import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';

const handleInvite = (email) => {}
export default function InviteFriends(props) {
    const [email, setEmail] = useState("");
    return (
        <div className="jumbotron  d-flex align-items-center justify-content-center h-100">
            <div className="justify-content-center align-items-center col-md-5">
            <h1 className="h1 display-4 strong">Let your friends also enjoy this platform.</h1>
            <p className="lead">You can send invitation to your friends by entering their email address.</p>
            <Form>
            <Form.Group controlId="formBasicEmail">
            <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} required />
            <Button variant="primary" type="button" className="btn-block" onClick={() => handleInvite(email)} >
                Send Invite
            </Button>
            </Form.Group>
            </Form>
            </div>
        </div>
    )

}