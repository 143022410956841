const config = {
    SESSION_JS_URL: process.env.REACT_APP_SESSION_JS_URL,
    AUTH_LOGIN_URL: process.env.REACT_APP_AUTH_LOGIN_URL,
    AUTH_LOGOUT_URL: process.env.REACT_APP_AUTH_LOGOUT_URL,
    AUTH_REGISTER_URL: process.env.REACT_APP_AUTH_REGISTER_URL,
    appshome: {
        API_HOST: process.env.REACT_APP_APPSHOME_API_HOST,
        CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    }
}
export default config;