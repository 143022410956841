import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { A } from 'hookrouter';
export default function Sidebar(props) {
    const handleMenuClick = () => {
        if (!props.isLGBreakPoint) {
            props.toggleSidebar(false);
        }
    }
    return (
        <Navbar variant="dark" className="h-100" style={{backgroundColor:'#231F20'}}>
            <Nav className="h-100 flex-column">
                <Nav.Item>
                    <A href="/apps" className="nav-link" onClick={handleMenuClick}>Apps</A>
                </Nav.Item>
                <Nav.Item>
                    <A href="/apps-coming-soon" className="nav-link" onClick={handleMenuClick}>Coming soon</A>
                </Nav.Item>
                {props.isAuthenticated && <Nav.Item>
                    <A href="/invite-friends" className="nav-link" onClick={handleMenuClick}>Invite friends</A>
                </Nav.Item>}
            </Nav>
        </Navbar>
    );
}